// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import keycloakConfig from "./keycloak.config";

export const environment = {
  baseUrl: 'http://82.165.212.140:3050/admin/v1/',
  localUrl: 'http://82.165.212.140:8090/admin/v1/',
  basePublicUrl: 'http://82.165.212.140:3050/public/v1/',
  url: 'http://82.165.212.140:3050/',
  secureUrl: 'http://82.165.212.140:3050/secured/v1/',
  moneyurl: 'http://82.165.212.140:8060/money-service/',
  providerurl: 'http://82.165.212.140:3001/admin/',
  /*baseUrl: 'http://localhost:3050/admin/v1/',
  localUrl: 'http://82.165.212.140:8090/admin/v1/',
  basePublicUrl: 'http://localhost:3050/public/v1/',
  url: 'http://localhost:3050/',
  secureUrl: 'http://localhost:3050/secured/v1/',
  moneyurl: 'http://82.165.212.140:8060/money-service/',
  providerurl: 'http://82.165.212.140:3001/admin/',*/
  keycloak: keycloakConfig,
  production: false,
};
