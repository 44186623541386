<div class="row">
  <div class="col-12 mt-3">
    <div
      class="table-responsive p-3"
      *ngIf="values | async as response;"
    >
      <table
        class="table table-bordered align-middle"
        aria-describedby="default table"
      >
        <thead>
          <th scope="col">
            {{ firstColumn }}
          </th>
          <th scope="col" *ngFor="let column of columns">
            {{ column.name }}
          </th>
          <th scope="col" *ngIf="hasOptions">Options</th>
        </thead>
        <tbody class="table-group-divider" *ngIf="((response.data) ? response.total || response!.data!.length !=0 :  response.totalElements || response!.datas!.length !=0) else noDat">
          <tr *ngFor="let item of (response.data) ? response.data : response.datas">
            <td *ngFor="let col of mColumns">
              <ng-container *ngIf="col.propertyName === 'status'">
                <span
                  *ngIf="col.propertyName === 'status'"
                  class="badge"
                  [ngClass]="{

                  'bg-success': item.status === userStatus.ACTIF || item.status === generalStatus.ACTIVE || item.status === transactionStatus.SUCCESS,
                  'bg-warning': item.status === transactionStatus.PENDING,
                  'bg-dark': item.status === transactionStatus.PROCESS,
                  'bg-danger': item.status === userStatus.BLOQUE || item.status === generalStatus.BLOCKED || item.status === transactionStatus.FAILURE,

                }"
                >{{ item.status }}</span
                >
              </ng-container>
              <ng-container *ngIf="col.propertyName === 'accountList'">
                <span
                  style="color: green; "
                  *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2) }}  <hr> </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'serviceList'">
                <span
                  style="color: #335D83;"
                   *ngFor="let service of item[col.propertyName]"
                >{{ service }} <hr> </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'bankList'">
                <span
                  style="color: #335D83;"
                  *ngFor="let bank of item[col.propertyName]"
                >{{ bank }} <hr> </span>

              </ng-container>

              <!--<ng-container *ngIf="col.propertyName === 'accounts'">
                <span
                  style="color: green; "
                  [hidden]="account.type === 'CREDIT' || account.type === 'EPARGNE' || account.type === 'PARRAINAGE' || account.type === 'INTERET'"  *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2) }} <hr> </span>
                <span style="color: #335D83;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'EPARGNE' || account.type === 'PARRAINAGE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}} <hr> </span>
                <span style="color: indigo;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'EPARGNE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  <hr> </span>
                <span style="color: #D25229;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'PARRAINAGE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  </span>

                <span style="color: #D25229;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'PARRAINAGE' || account.type === 'EPARGNE'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  </span>

              </ng-container>-->

              <ng-container *ngIf="col.propertyName === 'accounts'">
                <span
                  style="color: green; "
                 *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2) }} <hr> </span>
                <!--<span style="color: #335D83;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'EPARGNE' || account.type === 'PARRAINAGE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}} <hr> </span>
                <span style="color: indigo;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'EPARGNE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  <hr> </span>
                <span style="color: #D25229;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'PARRAINAGE' || account.type === 'INTERET'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  </span>

                <span style="color: #D25229;" [hidden]="account.type === 'PRINCIPAL' || account.type === 'CREDIT' || account.type === 'PARRAINAGE' || account.type === 'EPARGNE'"
                      *ngFor="let account of item[col.propertyName]"
                >{{ account.availableBalance.toFixed(2)}}  </span>-->

              </ng-container>


              <ng-container *ngIf="col.propertyName === 'intervals'">
                <span
                  style="color: green"
                    *ngFor="let interval of item[col.propertyName]"
                >{{ interval.minValue }} - {{ interval.maxValue }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'operation'">
                <span
                >{{ item['operation'].code }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'questionFr'">
                <span
                >{{ item['question'].fr }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'questionEn'">
                <span
                >{{ item['question'].en }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'answerFr'">
                <span
                >{{ item['answer'].fr }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'answerEn'">
                <span
                >{{ item['answer'].en }} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'flux'">
                <span
                  style="color: green"
                >{{ item['fluxIn'].toFixed(2) }} <hr> {{ item['fluxOut'].toFixed(2) }} </span>

              </ng-container>


              <ng-container *ngIf="col.propertyName === 'username'">
                <span
                >{{ item['user'].firstName }} {{ item['user'].lastName }} </span>

              </ng-container>


              <ng-container *ngIf="col.propertyName === 'feesType'">
                <span
                  style="color: #335D83"
                  *ngFor="let interval of item['intervals']"
                > <span *ngIf="interval.feesType === 'VALUE'">Valeur</span> <span *ngIf="interval.feesType === 'PERCENT'">Pourcentage</span>  </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'rate'">
                <span *ngIf="item[col.propertyName]"
                  style="color: #D25229"
                >  {{item[col.propertyName]!.destinationAmount!.toFixed(4)}} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'plafond'">
                <span
                  style="color: #335D83"
                >  {{item[col.propertyName]}} </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'exchangeRate'">
                <span
                  style="color: #D25229">
                  {{item['exchangeRate'][0].destinationAmount.toFixed(3)}}
                </span>

              </ng-container>


              <ng-container *ngIf="col.propertyName === 'comType'">
                <span
                  style="color: darkblue"
                  *ngFor="let interval of item['intervals']"
                ><span *ngIf="interval.feesType === 'PERCENT'">Pourcentage</span> <span *ngIf="interval.feesType === 'VALUE'">Valeur</span>  </span>

              </ng-container>


              <ng-container *ngIf="col.propertyName === 'settings'">
                <span
                    *ngFor="let setting of item[col.propertyName]"
                >{{ setting.name}} : {{setting.value}} <br> </span>

              </ng-container>

              <ng-container *ngIf="col.propertyName === 'roles'">
                <span
                  class="badge"
                  [ngClass]="{
                   'bg-primary': item === role.ROOT,
                  'bg-secondary': item === role.CLIENT,
                  'bg-dark': item === role.AGENT
                }"
                  *ngFor="let item of item[col.propertyName]"
                >{{ item }} </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName === 'maxAmount'">
                <span
                >[0 - {{ item[col.propertyName] }}] </span>
              </ng-container>
              <ng-container *ngIf="col.propertyName == 'lowerRounding'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   On
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Off
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'canCreateAccount'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   On
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Off
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'isWeb'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'isMobile'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'isActive'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   On
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Off
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'canLogIn'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   On
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Off
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'firstName'">
                <span>
                   {{item['firstName']  }} {{item['lastName']  }}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'balanceBefore'">
                <span>
                   {{item['balanceBefore'].toFixed(2)  }}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'balanceAfter'">
                <span>
                   {{item['balanceAfter'].toFixed(2)  }}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'message'">
                <span *ngIf="item['message'] != null">
                   {{createExcerpt(item['message'], 10) }}
               </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'paymentChannel'">
                <span *ngIf="item['paymentChannel'] != null">
                   {{createExcerpt(item['paymentChannel'], 10) }}
               </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'fName'">
                <span *ngIf="item.userr">
                   {{item!.userr['firstName']!  }} {{item!.userr['lastName']!  }}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'fPhone'">
                <span *ngIf="item.userr">
                   {{item!.userr['phone']!}}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'registerType'">
                <span>
                   {{item!['registerType']}}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'registrationNumber'">
                <span>
                   {{item!['registrationNumber']}}
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'isDefault'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   On
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Off
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'firstConnexion'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-danger': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-success': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>
                <ng-container *ngIf="col.propertyName == 'ceilingAction'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                  <span *ngIf="!item[col.propertyName]" class="badge"
                        [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'feesCommissionFromDest'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'verified'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'activated'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Activé
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Desactivé
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'smsToSource'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'mailToSource'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'smsToDestination'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'mailToDestination'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'subscriptionAction'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'financialImpact'">
                <span  *ngIf="item[col.propertyName]" class="badge"
                       [ngClass]="{
                  'bg-success': item[col.propertyName],

                }">
                   Oui
              </span>
                <span *ngIf="!item[col.propertyName]" class="badge"
                      [ngClass]="{
                  'bg-danger': !item[col.propertyName],
                }">
                   Non
              </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'flag'">
                 <span>
                   <img
                     class="w-full h-full rounded-full"
                     [src]="download(item[col.propertyName])" alt="" [width]="40"
                     [height]="30"
                     alt="flag">
                 </span>
              </ng-container>


              <ng-container *ngIf="col.propertyName == 'imageNotification'">
                 <span>
                   <img
                     class="w-full h-full rounded-full"
                     *ngIf="(item[col.propertyName] != null && item[col.propertyName] != '' && item[col.propertyName] != undefined)"
                     [src]="item[col.propertyName]" alt="" [width]="70"
                     [height]="70"
                     alt="notification">
                     <img style=" border-radius: 50%;"
                          *ngIf="(item[col.propertyName] == null || item[col.propertyName] == '' || item[col.propertyName] == undefined)"
                          [width]="70"
                          [height]="70" class="b-r-10" src="../assets/images/email/1.jpg" alt="">
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'image'">
                 <span>
                   <img
                     style=" border-radius: 50%;"
                     *ngIf="item['identity'] != null && (item.identity['imageVerso'])"
                     class="rounded-full"
                     [src]="download(item.identity['imageVerso'])" alt="" [width]="70"
                     [height]="70"
                     alt="avatar">

                   <img style=" border-radius: 50%;"
                        *ngIf="!(item['identity'] != null && (item.identity['imageVerso']))"
                        [width]="70"
                        [height]="70" class="b-r-10" src="../assets/images/user.jpg" alt="">
                 </span>
              </ng-container>


              <ng-container *ngIf="col.propertyName == 'gender'">
                 <span>


                   <img style=" border-radius: 50%;"
                        *ngIf=" item['gender'] == 'HOMME'"
                        [width]="70"
                        [height]="70" class="b-r-10" src="../assets/images/user.jpg" alt="">
                   <img style=" border-radius: 50%;"
                        *ngIf="item['gender'] == 'FEMME'"
                        [width]="70"
                        [height]="70" class="b-r-10" src="../assets/images/woman.png" alt="">
                 </span>
              </ng-container>


            <ng-container *ngIf="col.propertyName == 'updateAt'">
                <span>
                  {{item[col.propertyName] | date:'dd/MM/yy HH:mm'}}
                 </span>
            </ng-container>

              <ng-container *ngIf="col.propertyName == 'date'">
                <span>
                  {{item[col.propertyName] | date:'dd/MM/yy HH:mm'}}
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'endDate'">
                <span>
                  {{item[col.propertyName] | date:'dd/MM/yy HH:mm'}}
                 </span>
              </ng-container>
              <ng-container *ngIf="col.propertyName == 'updatedAt'">
                <span>
                  {{item[col.propertyName] | date:'dd/MM/yy HH:mm'}}
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName == 'createdAt'">
                <span>
                  {{item[col.propertyName] | date:'dd/MM/yy HH:mm'}}
                 </span>
              </ng-container>


              <ng-container *ngIf="col.propertyName == 'dateOfBirth'">
                <span>
                  {{item[col.propertyName] | date}}
                 </span>
              </ng-container>

              <ng-container *ngIf="col.propertyName != 'flag'
                &&col.propertyName !='image'
                &&col.propertyName != 'imageNotification'
                &&col.propertyName !='lowerRounding'
                &&col.propertyName !='firstConnexion'
                && col.propertyName !='updateAt'
                && col.propertyName !='paymentChannel'
                && col.propertyName !='updatedAt'
                && col.propertyName !='dateOfBirth'
                && col.propertyName !='balanceBefore'
                && col.propertyName !='balanceAfter'
                && col.propertyName !='ceilingAction'
                && col.propertyName !='canCreateAccount'
                 && col.propertyName !='canLogIn'
                 && col.propertyName !='username'
                 && col.propertyName !='isWeb'
                 && col.propertyName !='isMobile'
                 && col.propertyName !='operation'
                 && col.propertyName !='firstName'
                 && col.propertyName !='registerType'
                 && col.propertyName !='activated'
                  && col.propertyName !='fName'
                   && col.propertyName !='fPhone'
                 && col.propertyName !='status'
                  && col.propertyName !='exchangeRate'
                  && col.propertyName !='registrationNumber'
                 && col.propertyName !='accountList'
                 && col.propertyName !='serviceList'
                 && col.propertyName !='bankList'
                 && col.propertyName !='accounts'
                  && col.propertyName !='date'
                 && col.propertyName !='financialImpact'
                 && col.propertyName !='smsToSource'
                 && col.propertyName !='questionFr'
                 && col.propertyName !='questionEn'
                 && col.propertyName !='gender'
                 && col.propertyName !='answerFr'
                 && col.propertyName !='answerEn'
                 && col.propertyName !='feesType'
                 && col.propertyName !='comType'
                  && col.propertyName !='flux'
                  && col.propertyName !='plafond'
                 && col.propertyName !='mailToSource'
                  && col.propertyName !='createdAt'
                   && col.propertyName !='endDate'
                 && col.propertyName !='smsToDestination'
                 && col.propertyName !='mailToDestination'
                 && col.propertyName !='subscriptionAction'
                  && col.propertyName !='rate'
                 && col.propertyName !='feesCommissionFromDest'
                  && col.propertyName !='intervals'
                 && col.propertyName !='roles'
                 && col.propertyName !='maxAmount'
                 && col.propertyName !='settings'
                 && col.propertyName !='message'
                 && col.propertyName !='isActive'
                 && col.propertyName !='verified'
                    && col.propertyName !='isDefault'">
                 <span>
                    {{ item[col.propertyName] }}
                   </span>
              </ng-container>


            </td>



            <td *ngIf="hasOptions">
              <a
                title="Modifier"
                style="margin-right: 5%"
                *ngIf="isEditable || isOnlyForRoot"
                (click)="edit(item)"
                >
                <span
                  style="color: #335D83"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen055.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
               </a>

              <a
                title="Telecharger"
                style="margin-right: 5%"
                *ngIf="isDownloadable || isOnlyForRoot"
                (click)="downloadInvoice(item)"
              >
                <span
                  *ngIf="item.status === transactionStatus.SUCCESS"
                  style="color: #335D83; width: 50%"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen005.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
              </a>

              <a
                title="Activer/Désactiver"
                style="margin-right: 5%"
                *ngIf="(isHideable || isOnlyForRoot)"
                (click)="hideOrShow(item[idRef])"
                >
                <span
                  style="color: #D25229"
                  *ngIf="item.status === userStatus.ACTIF  || item.isActive === true"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen040.svg'"
                  class="svg-icon svg-icon-3"
                ></span>
                <span
                  style="color: green"
                  *ngIf="item.status === userStatus.BLOQUE  || item.isActive === false"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen037.svg'"
                  class="svg-icon svg-icon-3"
                ></span>

                </a>

              <a
                title="Activer/Désactiver cette transaction"
                style="margin-right: 5%"
                *ngIf="(isHideable || isOnlyForRoot)"
                (click)="hideOrShow(item[idRef])"
              >
                <span
                  style="color: #D25229"
                  *ngIf="item.status === transactionStatus.PENDING || item.status === transactionStatus.PROCESS "
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen023.svg'"
                  class="svg-icon svg-icon-3"
                ></span>

              </a>

              <a
                title="Rejouer cette transaction"
                style="margin-right: 5%"
                *ngIf="(isReplay || isOnlyForRoot)"
                (click)="replay(item[idRef])"
              >
                <span
                  style="color: grey"
                  *ngIf="item.status === transactionStatus.PENDING"
                  [inlineSVG]="'./assets/images/icons/duotune/abstract/abs013.svg'"
                  class="svg-icon svg-icon-3"
                ></span>

              </a>

              <a
                title="Supprimer"
                style="margin-right: 5%"
                *ngIf="isDeletable || isOnlyForRoot"
                (click)="delete(item)"
                >
                <span
                  style="color: #D25229"
                  [inlineSVG]="'./assets/images/icons/duotune/general/gen027.svg'"
                  class="svg-icon svg-icon-3"
                ></span></a>

              <a
                title="Détails"
                style="margin-right: 5%"
                *ngIf="hasDetails"
                (click)="showDetails(item)"
                > <span
                style="color: darkorchid"
                [inlineSVG]="'./assets/images/icons/duotune/general/gen022.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>

              <a
                title="Transactions"
                style="margin-right: 5%"
                *ngIf="hasReport"
                [hidden]="item.firstConnexion"
                (click)="showReport(item)"
              > <span
                style="color: blue"
                [inlineSVG]="'./assets/images/icons/duotune/files/fil004.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>

              <a
                title="Status de la verification"
                style="margin-right: 5%"
                *ngIf="hasVeriff"
                [hidden]="!(item.identity?.veriffSessionStatus && item.identity.veriffSessionStatus === 'created') "
                (click)="showVeriff(item)"
              > <span
                style="color: forestgreen"
                [inlineSVG]="'./assets/images/icons/duotune/general/gen012.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>

              <a
                title="Status de la verification"
                style="margin-right: 5%"
                *ngIf="hasVeriffTrans"
                [hidden]="!(item.operation.code === 'ASK_FUND' && item.beneficiary?.identity && item.beneficiary?.identity?.veriffSessionStatus && item.beneficiary?.identity?.veriffSessionStatus === 'created')"
                (click)="showVeriffTrans(item)"
              > <span
                style="color: forestgreen"
                [inlineSVG]="'./assets/images/icons/duotune/general/gen012.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>

              <a
                title="Filleuils"
                style="margin-right: 5%"
                *ngIf="hasFilleuil"
                [hidden]="item.firstConnexion || !item.activated"
                (click)="showFilleuil(item)"
              > <span
                style="color: yellowgreen"
                [inlineSVG]="'./assets/images/icons/duotune/communication/com013.svg'"
                class="svg-icon svg-icon-3"
              ></span></a>
            </td>

          </tr>
        </tbody>
      </table>


      <div class=" mt-2">

        <ngb-pagination
          [hidden] = "hasHide"
          class="d-flex justify-content-center mt-3 pagination pagination-primary"
          [(page)]="page"
          (pageChange)="onPageChange(page)"
          [pageSize]="pageSize"
          [collectionSize]="(response.total) ? response.total : response.totalElements"
          [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
        ></ngb-pagination>

      </div>



    </div>
  </div>
</div>

<ng-template #noDat>

  <tbody>
  <tr>
    <td class="text-center card-label fw-bolder fs-3" colspan="15">Aucune donnée trouvée</td>
  </tr>
  </tbody>

</ng-template>
