/**
 * Here you can add the configuration related to keycloak
 * So we can use this common config for diffrent environment
 */
import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'http://66.179.252.45:8000/',
  realm: 'nfs',
  clientId: 'nfs-admin',
};

export default keycloakConfig;
