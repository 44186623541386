export enum Status {
  ACTIVE = 'ACTIF',
  BLOCKED = 'BLOQUE',

}

export enum UserStatus {
  //WAITING_VALIDATION = 'SUSPENDU',
  ACTIF = 'ACTIF',
  BLOQUE = 'BLOQUE',
}



export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING'

}


export enum GenderStatus {
  HOMME = 'HOMME',
  FEMME = 'FEMME'
}

export enum RegisterType {
  CNI = 'CNI',
  PASSEPORT = 'PASSEPORT',
  PERMIS = 'PERMIS'
}
